.about{
    margin: 2rem;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.about-IITK, .about-EE{
    /* background: url(../images/slide2.jpg); */
    padding: 1rem 1rem;
    border-radius: 20px;
    box-shadow: 2px 2px 10px 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    .aboutt{
        margin: 2rem;
        width: 50%;
    }

}

.about-EE{

    animation: appearee ease-in-out both;
   /* animation-timeline: view();
   animation-range: entry 50% cover 50%; */
    /* transform: tra
    nslateX(-151%);
    animation: appear 0.8s; */
    /* transition: ease-in-out 1s; */ 
    /* animation: appearRight 1s ease; */
}
.reach-IITK{
    /* animation: reachiit ease-in-out both;
    animation-duration: 3s; */
   /* animation-timeline: view();
   animation-range: entry 70%; */
    display: flex;
    text-align: center;
    padding: 1rem 1rem;
    box-shadow: 2px 2px 9px 1px;
    padding:2rem;
}

.aboutt{
    p{
        text-align: justify;
    }
    h3{
        text-align: start;
        color:rgb(103, 102, 102)
    }
}

/* @keyframes appeariitK{
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }

    40% {
        opacity: 0;
        transform: translateX(-20%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
    
} */
@keyframes appearee{
    0% {
        opacity: 0;
        transform: translateX(50%);
    }

    40% {
        opacity: 0.7;
        transform: translateX(20%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
    
}

@keyframes reachiit {
    0% {
        opacity: 0;
        scale: 0.5;
    }

    100%{
        opacity: 1;
        scale: 1;
    }
}
/* @keyframes appearLeft{
    from{
        transform: translateX(-100%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
    
} */
/* @keyframes appearRight{
    from{
        transform: translateX(100%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
    
} */


@media screen and (max-width: 768px){
    .about-IITK, .about-EE{
        flex-direction: column;

        .aboutt{
            width: 100%;
        }
    }


    .about
    {
        margin: 1rem;
    }

    
}