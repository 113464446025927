.speakers{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 20px;
    margin: 0.875rem;
    justify-items: center;
    justify-content: center;
}
.infoBox{
    display:none;
    background-color: #ffffff;
    width : 80%;
    padding: 20px 40px;
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-family: "Poppins", sans-serif;
    text-align: center;
    z-index: 3;
    /* transition: display 10s, animation 2s; */
    p{
        font-size: 0.9rem;
    }

}

.popupContent{
    animation: fadein 1s ease-in-out;
    p{
        text-align: justify;
    }
}

.popupClose{
    position: fixed;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #ff6347cf;
}

.popupClose:hover{
    color: #ff0303;
}


.activeL{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background: black;
    opacity: 0.5;
}
.layer{
    width: 100%;
    z-index: 2;
}

@media (max-width : 450px){
    .infobox{
        width: 95%;
        top:50%;
        padding: 10px 20px 5px 20px !important;
        p{
            font-size: 0.7rem;
            line-height: 1.5;
        }
    }
    .popupClose{
        top: 5px;
        right: 10px;
    }
}