table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  tr:nth-child(even) {
    background-color:   
   #f2f2f2;
  }

  